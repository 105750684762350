import { captureRemixErrorBoundaryError } from "@sentry/remix";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  json,
  useLocation,
  useRouteError,
} from "@remix-run/react";
import "./tailwind.css";
import { LoaderFunctionArgs } from "@remix-run/node";
import { getUser } from "./session.server";
import { useEffect } from "react";
import { registerServiceWorker } from "~/helper/registerServiceWorker";
import SuperTokens, { SuperTokensWrapper } from "supertokens-auth-react";
import { SessionAuth } from "supertokens-auth-react/recipe/session";
import { frontendConfig } from "./config/frontend";

if (typeof window !== "undefined") {
  SuperTokens.init(frontendConfig());
}

export const loader = async ({ request }: LoaderFunctionArgs) => {
  return json({ user: await getUser(request) });
};

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <html lang="en" className="h-full bg-white">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <link rel="manifest" href="/manifest.webmanifest" />
        <Links />
      </head>
      <body className="h-full">
        {children}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

const UNPROTECTED_ROUTES = [
  '/',
]

export const ErrorBoundary = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  return <div>Something went wrong</div>;
};

export default function App() {
  const location = useLocation();
  const isUnprotectedRoute = location.pathname.startsWith("/auth") || UNPROTECTED_ROUTES.some(r => location.pathname == r);

  useEffect(() => {
    registerServiceWorker();
  }, []);

  return (
    <SuperTokensWrapper>
      {isUnprotectedRoute ? (
        <Outlet />
      ) : (
        <SessionAuth>
          <Outlet />
        </SessionAuth>
      )}

      <ScrollRestoration />
      <Scripts />
    </SuperTokensWrapper>)
}